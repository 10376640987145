<template>
    <b-modal id="IRLExplainModal" modal-class="modal-w-lg" hide-footer>
        <p>SwitchPitch’s IRL (Innovation Readiness Level) score is crafted to provide a comprehensive evaluation of a startup’s readiness for corporate partnerships.</p>
        <p>Operating on a scale ranging from 1 to 10, this score is computed by corporate startup traction, depth of engagement with corporate entities, frequency of visits to a startup’s profile, as well as startup traction data from outside databases.</p>
        <p><strong>Scores of 6 or higher</strong> indicate that startups have substantial interaction with corporate clients based on SwitchPitch’s intelligence. <strong>Scores ranging from 1 to 5</strong> suggest a limited or unknown level of interaction between startups and corporate clients.</p>
    </b-modal>
</template>

<script>

export default {
    methods: {
        openModal() {
            this.$bvModal.show('IRLExplainModal');
        }
    }
}
</script>

<style scoped>

</style>
